import { LampDemo } from "../components/Elements/lamp";
import Navbardemo from "../components/Elements/navbar";
import { Grid2 } from "@mui/material";
import { TextRevealCardPreview } from "../components/Elements/text-reveal"
import { CardStackDemo } from "../components/Elements/cardstack";
import { BentoGridDemo } from "../components/Elements/Bento_Grid";
import { InfiniteMovingCardsDemo } from "../components/Elements/infinitecards";

export default function Design(){

    return(
        <>
        <div className="maing">
          <Navbardemo></Navbardemo>
          <Grid2 container>
            <Grid2 size={{lg:6}}>
              <TextRevealCardPreview></TextRevealCardPreview>
            </Grid2>
            <Grid2 size={{lg:6}}> 
              <CardStackDemo></CardStackDemo>
            </Grid2>
          </Grid2>
          <BentoGridDemo></BentoGridDemo>
          <InfiniteMovingCardsDemo></InfiniteMovingCardsDemo>
          </div>
        </>
    )
}
