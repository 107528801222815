
import React from "react";
import {
  TextRevealCard,
  TextRevealCardDescription,
  TextRevealCardTitle,
} from "../ui/text-reveal-card";

export function TextRevealCardPreview() {
  return (
    (<div
      className="flex items-center justify-center bg-black h-[40rem] rounded-2xl w-full">
      <TextRevealCard text="The more you study" revealText="The more the rewards">
        <TextRevealCardTitle>
          Not your ordinary Ed-Tech Startup
        </TextRevealCardTitle>
        <TextRevealCardDescription>
          Hover over the card to reveal the hidden
          text.
        </TextRevealCardDescription>
      </TextRevealCard>
    </div>)
  );
}
